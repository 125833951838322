import pptxgen from "pptxgenjs";
import _ from "lodash";
import { clientConfig, CHART_COLORS } from "config";
import {
  addTemplates,
  getLayout,
  getChartLayout,
  getTableRows,
  getChartDetails,
  getMinMax,
  getShape
} from "./pptHelperFunctions";

const POSITIVE = "01B050";
const NEGATIVE = "FF0000";

const WATERFALL_COLORS = {
  red: "FF0000",
  green: "228B22",
  black: "000000",
  white: "transparent"
};

const addText = (slide, text) => {
  for (let i = 0; i < text.length; i += 1) {
    const { data, layout, display } = text[i];
    const {
      align,
      background_color: fill,
      rotation,
      font,
      link_address: url,
      line,
      vertical_anchor: valign
    } = display;
    const { color, name, size, bold } = font;
    slide.addText(data, {
      ...getLayout(layout),
      align,
      bold,
      color,
      fill: fill !== "FFFFFF" && fill,
      fontFace: name,
      fontSize: size,
      hyperlink: url && { url },
      line,
      rotate: rotation,
      valign: valign || "top"
    });
  }
};

const addImages = (slide, images) => {
  for (let i = 0; i < images.length; i += 1) {
    const { data, layout } = images[i];
    const path = data
      .replace("interrodata/insightautomation", "")
      .replace("src", "")
      .replace("//", "/");
    slide.addImage({
      path,
      ...getLayout(layout)
    });
  }
};

const addTables = (slide, tables) => {
  for (let i = 0; i < tables.length; i += 1) {
    const { data, layout, display } = tables[i];
    const { tp_highlight: highlight, gradient } = display;
    const rows = getTableRows(data, highlight, gradient);
    slide.addTable(rows, {
      ...getLayout(layout),
      align: "center",
      valign: "middle"
    });
  }
};

const addClusteredColumn = (ppt, slide, chart, colors) => {
  const { data, layout, display } = chart;
  const {
    data_labels: dataLabels,
    color,
    apply,
    category_axis: catAxis,
    value_axis: valAxis,
    gap_width: barGapWidthPct
  } = display;
  const {
    number_format: dataLabelFormatCode,
    position,
    font
  } = dataLabels || {};
  const chartData = Array.isArray(data.y[0])
    ? data.y.map(i => ({ labels: data.x, values: i[1] }))
    : [{ labels: data.x, values: data.y }];
  slide.addChart(ppt.ChartType.bar, chartData, {
    ...getChartLayout(layout),
    barGapWidthPct,
    catAxisHidden: catAxis.hidden,
    catAxisLabelPos: catAxis.tick_label_position === "None" && "none",
    valAxisHidden: true,
    valGridLine: { style: "none" },
    valAxisMinVal: valAxis.minimum_scale,
    valAxisMaxVal: valAxis.maximum_scale,
    showValue: dataLabels,
    dataLabelFormatCode,
    dataLabelPosition: position === "inside_end" ? "inEnd" : "outEnd",
    dataLabelFontSize: font?.size ?? 8,
    dataLabelColor: font?.color,
    chartColors: (color && [color]) || (apply && [POSITIVE]) || colors,
    invertedColors: (color && [color]) || (apply && [NEGATIVE]) || colors
  });
};

const addStackedColumn = (ppt, slide, chart, colors) => {
  const { data, layout, display } = chart;
  const {
    has_legend: showLegend,
    data_labels: dataLabels,
    apply,
    value_axis: valAxis,
    category_axis: catAxis,
    gap_width: barGapWidthPct,
    color_map: colorMap
  } = display;
  const { number_format: dataLabelFormatCode, font } = dataLabels || {};
  const {
    minimum_scale: valAxisMinVal,
    maximum_scale: valAxisMaxVal,
    tick_labels: tickLabels,
    axis_title: axisTitle,
    has_major_gridlines: isGridline,
    visible: valVisible = true
  } = valAxis;
  const { visible: catVisible = true } = catAxis;
  const { number_format: valAxisLabelFormatCode } = tickLabels || {};
  const chartData = data.y.map(i => ({
    labels: data.x,
    values: i[1],
    name: i[0]
  }));
  slide.addChart(ppt.ChartType.bar, chartData, {
    ...getChartLayout(layout),
    barGrouping: "stacked",
    barGapWidthPct,
    showLegend,
    legendFontSize: 8,
    catAxisHidden: !catVisible,
    valAxisHidden: !valVisible,
    valGridLine: !isGridline && { style: "none" },
    showValAxisTitle: axisTitle?.text_frame?.text,
    valAxisLabelFormatCode,
    valAxisMinVal,
    valAxisMaxVal,
    valAxisTitle: axisTitle?.text_frame?.text,
    showValue: dataLabels,
    dataLabelFormatCode,
    dataLabelFontSize: 8,
    dataLabelColor: font?.color,
    chartColors:
      (apply && data.y.map(i => WATERFALL_COLORS[i[0]])) ||
      (colorMap ? data.y.map((i, k) => colorMap[k] || colors[k]) : colors),
    invertedColors: (apply && data.y.map(i => WATERFALL_COLORS[i[0]])) || colors
  });
};

const addClusteredBar = (ppt, slide, chart) => {
  const { data, layout, display } = chart;
  const {
    data_labels: dataLabels,
    apply,
    value_axis: valAxis,
    category_axis: catAxis
  } = display;
  const { number_format: dataLabelFormatCode, position } = dataLabels || {};
  const {
    minimum_scale: valAxisMinVal,
    maximum_scale: valAxisMaxVal,
    has_title: showValAxisTitle,
    axis_title: axisTitle,
    has_major_gridlines: isGridline,
    visible: valVisible
  } = valAxis;
  const { visible: catVisible } = catAxis;
  slide.addChart(ppt.ChartType.bar, [{ labels: data.x, values: data.y }], {
    ...getChartLayout(layout),
    barDir: "bar",
    catAxisHidden: !catVisible,
    catAxisLabelFontSize: isGridline ? 8 : 6,
    catAxisLabelPos: "low",
    valAxisHidden: !valVisible,
    valGridLine: !isGridline && { style: "none" },
    showValAxisTitle,
    valAxisTitle: axisTitle?.text_frame?.text,
    valAxisMinVal,
    valAxisMaxVal,
    showValue: dataLabels,
    dataLabelFormatCode,
    dataLabelPosition: position === "inside_end" ? "inEnd" : "outEnd",
    dataLabelFontSize: isGridline ? 8 : 6,
    chartColors: apply && [POSITIVE],
    invertedColors: apply && [NEGATIVE]
  });
};

const addStackedBar = (ppt, slide, chart, colors) => {
  const { data, layout, display } = chart;
  const {
    has_legend: showLegend,
    data_labels: dataLabels,
    color_map: colorMap,
    legend
  } = display;
  const { number_format: dataLabelFormatCode, font } = dataLabels || {};
  const chartData = data.y.map(i => ({
    labels: data.x,
    values: i[1],
    name: i[0]
  }));
  const isUniqueX = _.uniq(data.x).length <= 1 && data.x.length > 1;
  slide.addChart(ppt.ChartType.bar, chartData, {
    ...getChartLayout(layout),
    barDir: "bar",
    barGrouping: "stacked",
    showLegend,
    legendFontSize: 8,
    legendPos: legend?.position === "bottom" && "b",
    catAxisLabelPos: isUniqueX && "none",
    catAxisLabelFontSize: 8,
    valAxisHidden: true,
    valGridLine: { style: "none" },
    showValue: dataLabels,
    dataLabelFormatCode,
    dataLabelFontSize: 8,
    dataLabelColor: font?.color,
    chartColors: colorMap
      ? data.y.map((i, k) => colorMap[k] || colors[k])
      : colors,
    invertedColors: colorMap
      ? data.y.map((i, k) => colorMap[k] || colors[k])
      : colors
  });
};

const addPieChart = (ppt, slide, chart, colors) => {
  const { data, layout } = chart;
  slide.addChart(ppt.ChartType.pie, [{ labels: data.x, values: data.y }], {
    ...getChartLayout(layout),
    showLabel: true,
    showPercent: true,
    chartColors: colors,
    dataLabelPosition: "ctr",
    dataLabelFontSize: 8,
    dataLabelColor: "FFFFFF"
  });
};

const addBubbleChart = (ppt, slide, chart) => {
  const { data, layout, display } = chart;
  const {
    color_map: colorMap,
    value_axis: valAxis,
    category_axis: catAxis
  } = display;
  const chartData = [
    { name: "X-Axis", values: data.y.map(i => i[0]) },
    ...data.y.map((i, k) => ({
      name: display.data_labels.individual_map[0][k],
      values: data.y.map((a, b) => (b === k ? i[1] : 0)),
      sizes: data.y.map((a, b) => (b === k ? i[2] : 0))
    }))
  ];
  slide.addChart(ppt.ChartType.bubble, chartData, {
    ...getChartLayout(layout),
    dataBorder: { style: "none" },
    showCatAxisTitle: true,
    catAxisLabelPos: "nextTo",
    catAxisTitle: catAxis.axis_title?.text_frame?.text,
    showValAxisTitle: true,
    valAxisTitle: valAxis.axis_title?.text_frame?.text,
    valGridLine: { style: "none" },
    showSerName: true,
    dataLabelPosition: "ctr",
    dataLabelFontSize: 8,
    chartColors: colorMap && data.y.map((i, k) => colorMap[0][k]),
    chartColorsOpacity: 50
  });
};

const addMultiChart = (ppt, slide, charts, colors) => {
  const { layout, display } = charts[0];
  const { legend, has_legend: showLegend } = display;
  const allValues = _.flattenDeep(
    charts.map(chart => chart.data.y.map(i => i[1]))
  );
  const isScaled = _.min(allValues) < 0;
  const comboTypes = charts.map((chart, index) => {
    const chartData = chart.data.y.map(i => ({
      name: i[0],
      labels: chart.data.x,
      values: i[1]
    }));
    return {
      ...getChartDetails(
        ppt,
        chart.data.type,
        chart.display.color_map,
        chart.data.y,
        index,
        colors
      ),
      data: chartData
    };
  });
  slide.addChart(comboTypes, {
    ...getChartLayout(layout),
    showLegend,
    legendPos: legend?.position[0],
    legendFontSize: 10,
    valAxes: charts.map(chart => {
      const { valAxisMinVal, valAxisMaxVal } = isScaled ? getMinMax(chart) : {};
      return {
        valAxisLabelFormatCode:
          chart.display.value_axis.tick_labels?.number_format,
        valAxisLabelFontSize: 12,
        valGridLine: { style: "none" },
        valAxisMinVal,
        valAxisMaxVal
      };
    }),
    catAxes: [
      { catAxisLabelFontSize: charts[0].data.x.length <= 13 ? 13 : 11 },
      { catAxisHidden: true }
    ]
  });
};

const addCharts = (ppt, slide, charts, colors) => {
  const singleCharts = [];
  const multiCharts = [];
  charts.forEach(chart =>
    chart.display.apply && chart.display.apply[0] === "multi"
      ? multiCharts.push(chart)
      : singleCharts.push(chart)
  );
  if (multiCharts.length > 0) {
    addMultiChart(ppt, slide, multiCharts, colors);
  }
  for (let i = 0; i < singleCharts.length; i += 1) {
    const { data } = singleCharts[i];
    if (data.type === "clustered_column") {
      addClusteredColumn(ppt, slide, charts[i], colors);
    } else if (data.type === "stacked_column") {
      addStackedColumn(ppt, slide, charts[i], colors);
    } else if (data.type === "clustered_bar") {
      addClusteredBar(ppt, slide, charts[i]);
    } else if (data.type === "stacked_bar") {
      addStackedBar(ppt, slide, charts[i], colors);
    } else if (data.type === "pie") {
      addPieChart(ppt, slide, charts[i], colors);
    } else if (data.type === "bubble") {
      addBubbleChart(ppt, slide, charts[i]);
    }
  }
};

const addShapes = (ppt, slide, shapes) => {
  for (let i = 0; i < shapes.length; i += 1) {
    const { data, layout, display } = shapes[i];
    const { type, color: fillColor, font } = display;
    const { color, size, bold } = font || {};
    slide.addText(data, {
      shape: getShape(ppt, type),
      ...getLayout(layout),
      align: "center",
      bold,
      color,
      fill: { color: fillColor },
      fontSize: size,
      shadow: { type: "outer", angle: 90, blur: 3, offset: 1, opacity: 0.35 },
      shrinkText: true
    });
  }
};

export const generatePPT = (report, client) => {
  const ppt = new pptxgen(); // eslint-disable-line new-cap
  const { font } = clientConfig[client];
  const { chartColors = CHART_COLORS } = clientConfig[client] || {};
  const colors = chartColors.map(i => i.replace("#", ""));
  ppt.layout = "LAYOUT_WIDE";
  ppt.theme = { headFontFace: font, bodyFontFace: font };
  addTemplates(ppt, client);
  for (let i = 0; i < report.length; i += 1) {
    const slideContent = report[i];
    const { hidden, text, images, tables, shapes, charts } = slideContent;
    const masterName = "MASTER_SLIDE";
    const slide = ppt.addSlide({ masterName });
    slide.hidden = hidden;
    const firstShapes = shapes?.filter(j => j.display.type === "rndrectangle");
    const lastShapes = shapes?.filter(j => j.display.type !== "rndrectangle");
    if (firstShapes) {
      addShapes(ppt, slide, firstShapes);
    }
    if (tables) {
      addTables(slide, tables);
    }
    if (images) {
      addImages(slide, images);
    }
    if (text) {
      addText(slide, text);
    }
    if (charts) {
      addCharts(ppt, slide, charts, colors);
    }
    if (lastShapes) {
      addShapes(ppt, slide, lastShapes);
    }
  }
  return ppt;
};

export default null;
