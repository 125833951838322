import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import PlotlyChart from "components/PlotlyChart";

const StackedBar = props => {
  const { data, display, colors, formatNumber } = props;
  const isUniqueX = _.uniq(data.x).length <= 1 && data.x.length > 1;
  return (
    <PlotlyChart
      data={data.y.map((e, key) => {
        let colour = colors[key % colors.length];
        if (display.apply) {
          colour =
            display.apply && _.isEqual(data.y[key][0], "white")
              ? "transparent"
              : data.y[key][0];
        } else if (display.color_map) {
          colour =
            display.color_map[key] === undefined
              ? colors[key % colors.length]
              : `#${display.color_map[key]}`;
        }
        return {
          x: e[1],
          y: isUniqueX ? data.x.map((i, k) => k) : data.x,
          type: "bar",
          orientation: "h",
          text:
            (display.data_labels &&
              display.data_labels.individual_map &&
              Object.values(display.data_labels.individual_map[key])) ||
            (display.data_labels &&
              !display.data_labels.individual_map &&
              e[1].map(j =>
                formatNumber(j, display.data_labels.number_format)
              )) ||
            null,
          textfont: {
            color:
              display.data_labels?.font?.color &&
              `#${display.data_labels.font.color}`,
            size: 14
          },
          textangle: 0,
          insidetextanchor: "middle",
          cliponaxis: false,
          name: e[0],
          marker: {
            color:
              display.apply || display.color_map
                ? colour
                : colors[key % colors.length],
            width: 1
          }
        };
      })}
      layout={{
        yaxis: {
          type: "category",
          tickfont: { size: 12 },
          showticklabels: !isUniqueX,
          automargin: true,
          showgrid: false
        },
        xaxis: {
          tickfont: { size: 12 },
          showticklabels: false,
          automargin: true,
          showgrid: false,
          zeroline: true,
          range: [
            display.value_axis.minimum_scale,
            display.value_axis.maximum_scale
          ]
        },
        barmode: "relative",
        showlegend: !!display.has_legend,
        legend: {
          traceorder: "normal",
          orientation: "h",
          font: { size: 10 }
        }
      }}
    />
  );
};

StackedBar.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  colors: PropTypes.arrayOf(PropTypes.string),
  formatNumber: PropTypes.func
};

StackedBar.defaultProps = {
  data: {},
  display: {},
  colors: [],
  formatNumber: () => {}
};

export default StackedBar;
