import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Box,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from "@mui/material";
import { ViewIcon as DefaultIcon } from "components/Icons";
import { CONTENT_DETAILS } from "constants/reportConstants";
import { replaceText } from "utils";
import ReportTypography from "../ReportTypography";

const showItems = (items, config) =>
  items.map(i => (
    <ListItem alignItems="flex-start" key={_.uniqueId()}>
      <ListItemAvatar>
        <Avatar
          sx={
            i.isChild
              ? {
                  bgcolor: "secondaryArray.50",
                  border: 1,
                  borderColor: "secondaryArray.100"
                }
              : { bgcolor: "secondaryArray.100" }
          }
        >
          {(i.icon && (
            <i.icon sx={{ color: "common.black", fontSize: 20 }} />
          )) || <DefaultIcon sx={{ color: "common.black", fontSize: 20 }} />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        <ReportTypography config={config} color="grey.800" fontWeight="medium">
          {i.name}
        </ReportTypography>
        <ReportTypography fontSize={14} config={config}>
          {i.description}
        </ReportTypography>
        {i.note && (
          <ReportTypography fontSize={12} fontStyle="italic" config={config}>
            {i.note}
          </ReportTypography>
        )}
      </ListItemText>
    </ListItem>
  ));

const Contents = props => {
  const { nav, config, story, text } = props;
  const contentsList = CONTENT_DETAILS[story];
  let replacements = false;
  if (text.length > 0) {
    const replacementText = text.find(i => i.key === "replacement_text")?.text;
    replacements =
      replacementText &&
      Object.assign(
        {},
        ...replacementText.map(i => {
          const [toReplace, replacement] = i.split("___");
          return { [toReplace]: replacement };
        })
      );
  }
  const contentsListKeys = _.keys(contentsList).filter(i =>
    i.match(/\{({*[^{}]*}*)\}/g)
  );
  for (let i = 0; i < contentsListKeys.length; i += 1) {
    const key = contentsListKeys[i];
    const replacedKey = replaceText(contentsList[key].name, replacements);
    contentsList[replacedKey.toLowerCase()] = {
      ...contentsList[key],
      name: replacedKey
    };
  }
  const contents = nav
    .map(i => i.section.toLowerCase())
    .map(i => contentsList[i])
    .filter(i => i);
  const middle = Math.ceil(contents.length / 2);
  const left = contents.slice(0, middle);
  const right = contents.slice(middle);
  return (
    <Box sx={{ height: 1, width: 1 }}>
      <Grid item xs={12} sx={{ mt: -3 }} container>
        <Grid item xs={6}>
          {showItems(left, config)}
        </Grid>
        <Grid item xs={6}>
          {showItems(right, config)}
        </Grid>
      </Grid>
    </Box>
  );
};

Contents.propTypes = {
  nav: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape(),
  story: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.shape())
};

Contents.defaultProps = {
  nav: [],
  config: {},
  story: "",
  text: []
};

export default Contents;
