import React from "react";
import PropTypes from "prop-types";
import { wrapText } from "utils";
import PlotlyChart from "components/PlotlyChart";

const ClusteredBar = props => {
  const { data, display, formatNumber } = props;
  const isGrid = display.value_axis.has_major_gridlines;
  const isCatAxis = display.category_axis.visible;
  return (
    <PlotlyChart
      data={[
        {
          x: data.y,
          y: data.x,
          type: "bar",
          orientation: "h",
          text:
            display.data_labels &&
            data.y.map(i => formatNumber(i, display.data_labels.number_format)),
          textposition:
            display.data_labels?.position === "inside_end"
              ? "inside"
              : "outside",
          textfont: {
            color: display.data_labels?.font?.color
              ? `#${display.data_labels.font.color}`
              : display.apply &&
                data.y.map(i => (i > 0 ? "#01B050" : "#FF0000")),
            size: isGrid ? 14 : 8
          },
          cliponaxis: false,
          marker: {
            color: display.color
              ? `#${display.color}`
              : display.apply &&
                data.y.map(i => (i > 0 ? "#01B050" : "#FF0000"))
          }
        }
      ]}
      layout={{
        yaxis: {
          type: "category",
          tickfont: { size: isGrid ? 12 : 8 },
          showticklabels: isGrid || isCatAxis,
          automargin: true,
          zeroline: true
        },
        xaxis: {
          showline: isGrid,
          showgrid: isGrid,
          showticklabels: isGrid,
          automargin: true,
          zeroline: isCatAxis,
          title:
            display.value_axis.has_title &&
            wrapText(display.value_axis.axis_title.text_frame.text, 30),
          titlefont: { size: 12 }
        }
      }}
    />
  );
};

ClusteredBar.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  formatNumber: PropTypes.func
};

ClusteredBar.defaultProps = {
  data: {},
  display: {},
  formatNumber: () => {}
};

export default ClusteredBar;
