import React from "react";
import PropTypes from "prop-types";
import theme from "theme";

const ReportShape = props => {
  const { data, display, layout } = props;
  return (
    <div className="Shape ReportShape">
      {display.type === "oval" ? (
        <div
          style={{
            backgroundColor: `#${display.color ? display.color : "fff"}`,
            color: `#${display.font ? display.font.color : "fff"}`,
            fontSize: "0.875em",
            textAlign: "center",
            position: "absolute",
            whiteSpace: "pre-wrap",
            top: `${layout.top}%`,
            left: `${layout.left}%`,
            height: `${layout.height}%`,
            width: `${layout.width}%`,
            borderRadius: "50%",
            border: `1px solid ${theme.palette.grey[100]}`,
            fontWeight: 500,
            boxShadow: `0 0 10px ${theme.palette.grey[200]}`,
            display: "flex",
            zIndex: 12
          }}
        >
          <p style={{ margin: "auto" }}>{data}</p>
        </div>
      ) : null}
      {display.type === "line" ? (
        <div
          style={{
            top: `${layout.begin_y}%`,
            left: `${layout.begin_x}%`,
            height: `${layout.end_y - layout.begin_y + 1}%`,
            width: `${layout.end_x - layout.begin_x + 1}%`,
            position: "absolute",
            textAlign: "center",
            backgroundColor: `#${display.color}`
          }}
        >
          {data}
        </div>
      ) : null}
      {display.type === "rndrectangle" ? (
        <div
          style={{
            backgroundColor: `#${display.color ? display.color : "fff"}`,
            color: "black",
            fontSize: "0.875em",
            textAlign: "center",
            position: "absolute",
            whiteSpace: "pre-wrap",
            top: `${layout.top}%`,
            left: `${layout.left}%`,
            height: `${layout.height}%`,
            width: `${layout.width}%`,
            borderRadius: 10,
            boxShadow: `0 0 10px ${theme.palette.grey[200]}`
          }}
        >
          <p style={{ margin: "auto" }}>{data}</p>
        </div>
      ) : null}
      {display.type === "arrow" || display.type === "uparrow" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            top: `${layout.top}%`,
            left: `${layout.left}%`,
            height: `${layout.height}%`,
            width: `${layout.width}%`,
            position: "absolute",
            textAlign: "center",
            zIndex: 15,
            transform: display.type === "arrow" ? "rotate(180deg)" : "rotate(0)"
          }}
        >
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: "24px solid transparent",
              borderRight: "24px solid transparent",
              borderBottom: `24px solid #${display.color}`
            }}
          />
          <div
            style={{
              width: "50%",
              height: "100%",
              backgroundColor: `#${display.color}`
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

ReportShape.propTypes = {
  data: PropTypes.string,
  display: PropTypes.shape(),
  layout: PropTypes.shape()
};

ReportShape.defaultProps = {
  data: "",
  display: {},
  layout: {}
};

export default ReportShape;
