import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { clientTemplates } from "config";

const ReportTemplate = props => {
  const { activeSlideIndex, client, story, formatKey } = props;
  const [template, setTemplate] = useState(null);
  useEffect(() => {
    const path = clientTemplates.includes(client)
      ? `/templates/${client}`
      : "/templates/default";
    // special case for ccep due to old templates for prr reports
    if (story === "prr") {
      setTemplate(`${path}/main_template.png`);
    } else if (activeSlideIndex === 0) {
      setTemplate(`${path}/title_template.png`);
    } else if (formatKey === "section") {
      setTemplate(`${path}/section_template.png`);
    } else setTemplate(`${path}/main_template.png`);
    return () => {
      setTemplate(null);
    };
  }, [activeSlideIndex]);
  return (
    <img
      src={template}
      alt={template}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%"
      }}
    />
  );
};

ReportTemplate.propTypes = {
  activeSlideIndex: PropTypes.number,
  client: PropTypes.string,
  story: PropTypes.string,
  formatKey: PropTypes.string
};

ReportTemplate.defaultProps = {
  activeSlideIndex: 0,
  client: "",
  story: "",
  formatKey: ""
};

export default ReportTemplate;
