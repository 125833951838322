import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  ListSubheader,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import {
  DownIcon,
  UpIcon,
  HomeIcon,
  ViewIcon as DefaultIcon,
  ComponentsAnalysisIcon,
  PerformanceOverviewIcon,
  NewProductListingsIcon,
  RateIcon,
  OffShelfIcon,
  AppendixIcon,
  SoundbitesIcon,
  ExecutiveSummaryIcon,
  DistributionChangesIcon,
  ContentsIcon,
  DriversOfChangeIcon,
  TopInsightsIcon,
  ContextIcon,
  ImpactIcon,
  DriversIcon,
  PerformanceSplitIcon,
  MarketSplitIcon,
  DeepDiveSkuIcon,
  ToolsIcon,
  RelatedReportsIcon,
  UnderPerformersIcon,
  StarPerformersIcon,
  SalesOveriewIcon,
  ShareIcon,
  NPDIcon
} from "components/Icons";

const styles = {
  root: {
    width: 1,
    px: 2,
    maxHeight: 600,
    overflowY: "auto",
    overflowX: "auto",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": { width: 3 },
    "&::-webkit-scrollbar-thumb": { bgcolor: "grey.300", borderRadius: 1 },
    "&::-webkit-scrollbar-track": { py: 1 },
    "& .MuiListItemIcon-root": { minWidth: "auto", mr: 1.25, ml: 1 },
    "& .MuiListItemButton-root:hover": {
      bgcolor: "rgba(0, 0, 0, 0.02)",
      borderRadius: 1
    },
    "& .MuiListItemButton-root span": {
      width: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "& .MuiListItemText-primary": { fontSize: 12, color: "grey.400" }
  },
  header: {
    color: "common.black",
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center"
  },
  parent: {
    color: "grey.400",
    "& .MuiListItemText-primary": { fontWeight: 500 },
    "&.MuiListItemButton-root.Mui-selected": {
      bgcolor: "grey.100",
      borderRadius: 1,
      mb: 0.5,
      "& .MuiListItemText-primary": { color: "grey.600", fontSize: 14 }
    }
  },
  child: {
    bgcolor: "grey.100",
    borderRadius: 1,
    "&.MuiList-root": { my: 1 },
    "& .MuiListItemButton-root.Mui-selected": {
      bgcolor: "grey.100",
      borderRadius: 1,
      "&:hover": theme => ({ bgcolor: `${theme.palette.secondary.main}10` }),
      "& .MuiListItemText-primary": { color: "secondary.main", fontWeight: 500 }
    }
  }
};

const getIcon = (section, isOpen) => {
  let IconName = "";
  switch (section?.toLowerCase()) {
    case "home":
      IconName = HomeIcon;
      break;
    case "contents":
      IconName = ContentsIcon;
      break;
    case "executive summary":
      IconName = ExecutiveSummaryIcon;
      break;
    case "soundbites":
      IconName = SoundbitesIcon;
      break;
    case "performance":
      IconName = PerformanceOverviewIcon;
      break;
    case "component analysis":
      IconName = ComponentsAnalysisIcon;
      break;
    case "new product listings":
      IconName = NewProductListingsIcon;
      break;
    case "distribution changes":
      IconName = DistributionChangesIcon;
      break;
    case "rate of sale and pack/price mix":
      IconName = RateIcon;
      break;
    case "off-shelf presence":
      IconName = OffShelfIcon;
      break;
    case "promo rate of sale and pack/price mix":
      IconName = RateIcon;
      break;
    case "drivers of change: overview":
      IconName = DriversOfChangeIcon;
      break;
    case "top insights":
      IconName = TopInsightsIcon;
      break;
    case "the context":
      IconName = ContextIcon;
      break;
    case "the impact":
      IconName = ImpactIcon;
      break;
    case "the drivers":
      IconName = DriversIcon;
      break;
    case "performance by hierarchy 1 and hierarchy 2 within":
      IconName = PerformanceSplitIcon;
      break;
    case "performance vs market parent excl. selected market":
      IconName = MarketSplitIcon;
      break;
    case "deep dive by sku":
      IconName = DeepDiveSkuIcon;
      break;
    case "new tools":
      IconName = ToolsIcon;
      break;
    case "appendix":
      IconName = AppendixIcon;
      break;
    case "related reports":
      IconName = RelatedReportsIcon;
      break;
    case "sales overview":
      IconName = SalesOveriewIcon;
      break;
    case "star-performers":
      IconName = StarPerformersIcon;
      break;
    case "under-performers":
      IconName = UnderPerformersIcon;
      break;
    case "share performance":
      IconName = ShareIcon;
      break;
    case "total performance":
      IconName = PerformanceOverviewIcon;
      break;
    case "npd focus":
      IconName = NewProductListingsIcon;
      break;
    case "npd categorisation":
      IconName = NPDIcon;
      break;
    default:
      IconName = DefaultIcon;
      break;
  }
  if (IconName === DefaultIcon) {
    if (section?.toLowerCase().includes("driver of change ")) {
      IconName = DriversOfChangeIcon;
    }
    if (section?.toLowerCase().includes("new tools")) {
      IconName = ToolsIcon;
    }
  }
  return (
    <IconName sx={{ fontSize: 20, color: isOpen ? "grey.600" : "grey.400" }} />
  );
};

const ReportNavigation = props => {
  const { selectedSlideKey, listItems, onSlideClicked, isNavigation } = props;
  const [selectedSection, setSelectedSection] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const section = listItems.find(
      item =>
        item.key === selectedSlideKey ||
        item.children.find(child => child.key === selectedSlideKey)
    );
    setSelectedSection(section);
  }, [selectedSlideKey]);

  useEffect(() => {
    if (isNavigation) {
      const container = document.getElementById("report-nav-container");
      const element = ref?.current;
      if (element) {
        container.scrollTop = element.offsetTop - container.offsetTop;
      }
    }
  }, [selectedSlideKey, selectedSection]);

  const handleClick = section => {
    setSelectedSection(section);
    onSlideClicked(section);
  };

  return (
    <Fragment>
      <ListSubheader component="div" sx={styles.header}>
        Report Navigation
      </ListSubheader>
      <List sx={styles.root} component="nav" id="report-nav-container">
        {listItems.map(item => {
          const isOpen = selectedSection?.key === item.key;
          if (!item.children.length) {
            return (
              <ListItemButton
                dense
                disableGutters
                key={item.key}
                ref={isOpen ? ref : null}
                onClick={() => handleClick(item)}
                selected={isOpen}
                sx={styles.parent}
              >
                <ListItemIcon>{getIcon(item.section, isOpen)}</ListItemIcon>
                <ListItemText primary={item.section} />
              </ListItemButton>
            );
          }
          const isParentSelected = selectedSlideKey === item.key;
          return (
            <Fragment key={`${item.key}-parent`}>
              <ListItemButton
                dense
                disableGutters
                onClick={() => handleClick(item)}
                ref={isParentSelected ? ref : null}
                selected={isOpen}
                sx={styles.parent}
              >
                <ListItemIcon>{getIcon(item.section, isOpen)}</ListItemIcon>
                <ListItemText primary={item.section} />
                {isOpen ? (
                  <UpIcon sx={{ fontSize: 16, mr: 0.5 }} />
                ) : (
                  <DownIcon sx={{ fontSize: 16, mr: 0.5 }} />
                )}
              </ListItemButton>
              {isOpen && (
                <List component="div" sx={styles.child} disablePadding>
                  {item.children.map(child => {
                    const isChildSelected = selectedSlideKey === child.key;
                    return (
                      <ListItemButton
                        key={child.key}
                        ref={isChildSelected ? ref : null}
                        selected={isChildSelected}
                        sx={styles.child}
                        onClick={() => onSlideClicked(child)}
                      >
                        <ListItemText primary={child.name} />
                      </ListItemButton>
                    );
                  })}
                </List>
              )}
            </Fragment>
          );
        })}
      </List>
    </Fragment>
  );
};

ReportNavigation.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.shape()),
  selectedSlideKey: PropTypes.string,
  onSlideClicked: PropTypes.func,
  isNavigation: PropTypes.bool
};

ReportNavigation.defaultProps = {
  listItems: [],
  selectedSlideKey: "",
  onSlideClicked: () => {},
  isNavigation: false
};

export default ReportNavigation;
