import React from "react";
import PropTypes from "prop-types";
import { getTickFormat, wrapText } from "utils";
import PlotlyChart from "components/PlotlyChart";

const LineChart = props => {
  const { data, display } = props;
  const { prefix, suffix } = getTickFormat(
    display.value_axis.tick_labels?.number_format
  );
  return (
    <PlotlyChart
      data={data.y.map((e, key) => ({
        x: data.x.map(i => wrapText(i, 12)),
        y: e[1],
        cliponaxis: false,
        name: e[0],
        marker: { color: display.color_map[key] }
      }))}
      layout={{
        xaxis: {
          type: "category",
          showline: false,
          showgrid: display.category_axis.has_major_gridlines,
          tickfont: {
            size: display.category_axis.tick_labels?.font?.size ?? "auto"
          },
          automargin: true,
          zeroline: true
        },
        yaxis: {
          tickprefix: prefix,
          ticksuffix: suffix,
          showline: display.value_axis.visible,
          showgrid: display.value_axis.has_major_gridlines,
          tickfont: {
            size: display.value_axis.tick_labels?.font?.size ?? "auto"
          },
          automargin: true,
          zeroline: true
        },
        legend: { orientation: "h", xanchor: "center", y: 1.2, x: 0.5 },
        showlegend: !!display.has_legend
      }}
    />
  );
};

LineChart.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape()
};

LineChart.defaultProps = {
  data: {},
  display: {}
};

export default LineChart;
