import React from "react";
import PropTypes from "prop-types";
import { wrapText } from "utils";
import PlotlyChart from "components/PlotlyChart";

const ClusteredColumn = props => {
  const { data, display, colors, formatNumber } = props;
  return (
    <PlotlyChart
      data={
        Array.isArray(data.y[0])
          ? data.y.map(e => ({
              x: data.x.map(i => wrapText(i, 12)),
              y: e[1],
              type: "bar",
              text: e[1].map(j =>
                formatNumber(j, display.data_labels?.number_format)
              ),
              textposition:
                (display.apply && "outside") ||
                (display.data_labels.position === "inside_end"
                  ? "auto"
                  : "outside"),
              textfont: {
                color:
                  display.apply &&
                  data.y.map(i => (i > 0 ? "#01B050" : "#FF0000")),
                size: 14
              },
              insidetextfont: { color: "#FFFFFF" },
              cliponaxis: false,
              name: e[0],
              marker: { color: e[1].map((i, k) => colors[k % colors.length]) }
            }))
          : [
              {
                x: data.x.map(i => wrapText(i, 12)),
                y: data.y,
                type: "bar",
                text:
                  display.data_labels &&
                  data.y.map(i =>
                    formatNumber(i, display.data_labels.number_format)
                  ),
                textposition:
                  (display.apply && "outside") ||
                  (display.data_labels.position === "inside_end"
                    ? "auto"
                    : "outside"),
                textfont: {
                  color:
                    display.apply &&
                    data.y.map(i => (i > 0 ? "#01B050" : "#FF0000")),
                  size: 14
                },
                insidetextfont: { color: "#FFFFFF" },
                cliponaxis: false,
                marker: {
                  color: display.color
                    ? `#${display.color}`
                    : display.apply &&
                      data.y.map(i => (i > 0 ? "#01B050" : "#FF0000"))
                }
              }
            ]
      }
      layout={{
        xaxis: {
          type: "category",
          showline: false,
          showticklabels: display.category_axis?.tick_label_position !== "None",
          tickfont: { size: 12 },
          automargin: true,
          zeroline: true
        },
        yaxis: {
          showticklabels: false,
          showgrid: false,
          zeroline: true,
          range: [
            display.value_axis?.minimum_scale,
            display.value_axis?.maximum_scale
          ]
        },
        barmode: Array.isArray(data.y[0]) && "group",
        bargap: display.gap_width && display.gap_width / 100,
        margin: { t: 40, b: 40, r: 10, l: 10 }
        // uniformtext: { minsize: 5, mode: "show" }
      }}
    />
  );
};

ClusteredColumn.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  colors: PropTypes.arrayOf(PropTypes.string),
  formatNumber: PropTypes.func
};

ClusteredColumn.defaultProps = {
  data: {},
  display: {},
  colors: [],
  formatNumber: () => {}
};

export default ClusteredColumn;
