import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { wrapText, getTickFormat } from "utils";
import PlotlyChart from "components/PlotlyChart";

const StackedColumn = props => {
  const { data, display, colors, formatNumber } = props;
  const { prefix, suffix } = getTickFormat(
    display.value_axis.tick_labels?.number_format
  );
  return (
    <PlotlyChart
      data={data.y.map((e, key) => {
        let colour = colors[key % colors.length];
        if (display.apply) {
          colour =
            display.apply && _.isEqual(data.y[key][0], "white")
              ? "transparent"
              : data.y[key][0];
        } else if (display.color_map) {
          colour =
            display.color_map[key] === undefined
              ? colors[key % colors.length]
              : `#${display.color_map[key]}`;
        }
        return {
          x: data.x.map(i => wrapText(i, 12)),
          y: e[1],
          type: "bar",
          text:
            (display.data_labels &&
              display.data_labels.individual_map &&
              Object.values(display.data_labels.individual_map[key])) ||
            (display.data_labels &&
              !display.data_labels.individual_map &&
              e[1].map(j =>
                formatNumber(j, display.data_labels.number_format)
              )) ||
            null,
          textfont: {
            color:
              display.data_labels?.font?.color &&
              `#${display.data_labels.font.color}`,
            size: 14
          },
          textangle: 0,
          insidetextanchor: "middle",
          cliponaxis: false,
          name: wrapText(e[0], 15),
          marker: {
            color:
              display.apply || display.color_map
                ? colour
                : colors[key % colors.length]
          }
        };
      })}
      layout={{
        xaxis: {
          type: "category",
          showticklabels: display.category_axis.visible,
          tickfont: { size: 12 },
          automargin: true
          // showgrid: display.category_axis.has_major_gridlines
        },
        yaxis: {
          tickprefix: prefix,
          ticksuffix: suffix,
          ticklen: 5,
          tickfont: { size: 12 },
          title: display.value_axis.axis_title?.text_frame?.text,
          visible: display.value_axis.visible,
          showline: display.value_axis.visible,
          zeroline: true,
          automargin: true,
          showgrid: display.apply,
          range: [
            display.value_axis.minimum_scale,
            display.value_axis.maximum_scale
          ]
        },
        barmode: "relative",
        showlegend: !!display.has_legend,
        legend: { font: { size: 12 } },
        bargap: display.gap_width && display.gap_width / 100
      }}
    />
  );
};

StackedColumn.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  colors: PropTypes.arrayOf(PropTypes.string),
  formatNumber: PropTypes.func
};

StackedColumn.defaultProps = {
  data: {},
  display: {},
  colors: [],
  formatNumber: () => {}
};

export default StackedColumn;
