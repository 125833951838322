import _ from "lodash";
import { scaleFormatter } from "utils";
import theme from "theme";

const SIDES_BOTTOM_BORDER = [
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") }
];

const RIGHT_BOTTOM_BORDER = [
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { type: "none" }
];

const SIDES_BORDER = [
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") },
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") }
];

const FULL_BORDER = [
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") },
  { color: theme.palette.grey[200].replace("#", "") }
];

const BOTTOM_BORDER = [
  { type: "none" },
  { type: "none" },
  { color: theme.palette.grey[200].replace("#", "") },
  { type: "none" }
];

const TEMPLATE_LAYOUT = { x: 0, y: 0, w: "100%", h: "100%" };

/**
 * Add slide templates to ppt
 */
export const addTemplates = (ppt, client) => {
  ppt.defineSlideMaster({
    title: "MASTER_SLIDE",
    objects: [
      {
        image: {
          ...TEMPLATE_LAYOUT,
          path: `/templates/${client}/main_template.png`
        }
      }
    ]
  });
};

/**
 * Get position and size based on layout
 */
export const getLayout = layout => {
  const { left, top, width, height } = layout;
  return { x: `${left}%`, y: `${top}%`, w: `${width}%`, h: `${height}%` };
};

/**
 * Get position and size of chart based on layout
 */
export const getChartLayout = layout => {
  const { left, top, width, height } = layout;
  return {
    x: (left / 100) * 13.3,
    y: (top / 100) * 7.5,
    w: `${width}%`,
    h: `${height}%`
  };
};

// table header
const getHeaderRow = (header, highlight, boldCells) =>
  header.map((i, key) => ({
    text: i,
    options: {
      bold:
        highlight && boldCells.find(o => o.x === 0 && o.y === key + 1) && true,
      border: FULL_BORDER,
      fontSize: 13
    }
  }));

// first column if used
const getIndexOptions = index =>
  index.map((i, k) => ({
    text: i,
    options: {
      fill: theme.palette.grey[100].replace("#", ""),
      border: k === index.length - 1 ? SIDES_BOTTOM_BORDER : SIDES_BORDER,
      fontSize: 10
    }
  }));

// main table rows
const getCellOptions = (data, gradient, colours) =>
  data.map((i, x) =>
    i.map((j, y) => ({
      text: j,
      options: {
        color:
          gradient && colours.find(o => o.x === x + 1 && o.y === y + 1)?.colour,
        fontSize: 12,
        border: y === i.length - 1 ? RIGHT_BOTTOM_BORDER : BOTTOM_BORDER
      }
    }))
  );

/**
 * Get the table rows from the backend data
 */
export const getTableRows = (tableData, highlight, gradient) => {
  const { columns, data, index } = JSON.parse(tableData);
  const colours = gradient?.map(i => ({
    x: i[0][0],
    y: i[0][1],
    colour: i[1]
  }));
  const boldCells =
    highlight &&
    (highlight[0].length > 1
      ? highlight?.map(i => ({ x: i[0], y: i[1] }))
      : [{ x: highlight[0], y: highlight[1] }]);
  const headerOptions = getHeaderRow(columns, highlight, boldCells);
  const indexOptions = getIndexOptions(index);
  const cellOptions = getCellOptions(data, gradient, colours);
  const rows = [
    index
      ? [
          {
            text: "",
            options: { border: FULL_BORDER }
          },
          ...headerOptions
        ]
      : headerOptions,
    ...(index
      ? cellOptions.map((row, k) => [indexOptions[k], ...row])
      : cellOptions)
  ];
  return rows;
};

/**
 * Get chart details of individual charts making up a multi chart
 */
export const getChartDetails = (ppt, type, colorMap, yData, index, colors) => {
  const isSecondary = index === 1;
  let chartColors = colors;
  if (colorMap) {
    chartColors = yData.map((i, k) => colorMap[k] || colors[k]);
  } else if (isSecondary) {
    chartColors = yData.map(
      (i, k) => colors[(k + yData.length) % colors.length]
    );
  }
  const options = {
    chartColors,
    invertedColors: chartColors,
    secondaryValAxis: isSecondary,
    secondaryCatAxis: isSecondary
  };
  switch (type) {
    case "clustered_column":
      return {
        type: ppt.ChartType.bar,
        options: { ...options }
      };
    case "stacked_column":
      return {
        type: ppt.ChartType.bar,
        options: { barGrouping: "stacked", ...options }
      };
    case "line":
      return {
        type: ppt.ChartType.line,
        options: { lineDataSymbol: "none", ...options }
      };
    default:
      return {};
  }
};

/**
 * Align zero lines for multiple y axes on multi chart using scale formatter
 * to set min and max values for secondary y axis
 */
export const getMinMax = chart => {
  const yData = _.flattenDeep(chart.data.y.map(i => i[1]));
  const { minScale, maxScale } = scaleFormatter(yData);
  return { valAxisMinVal: minScale, valAxisMaxVal: maxScale };
};

/**
 * Shape mapper to pptxgen shapes
 */
export const getShape = (ppt, type) => {
  switch (type) {
    case "oval":
      return ppt.ShapeType.ellipse;
    case "rndrectangle":
      return ppt.ShapeType.roundRect;
    case "arrow":
      return ppt.ShapeType.downArrow;
    default:
      return null;
  }
};

export default null;
