import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const getColors = colors =>
  Object.assign(
    colors && colors.length > 0
      ? colors.map(d => ({ x: d[0][0], y: d[0][1], colour: d[1] }))
      : []
  );

const getHighlight = highlight =>
  Object.assign(
    highlight && highlight.length > 0
      ? (highlight[0].length > 1 &&
          highlight.map(d => ({ x: d[0], y: d[1] }))) || [
          { x: highlight[0], y: highlight[1] }
        ]
      : []
  );

const ReportTable = props => {
  const { data, display, layout } = props;
  const tableData = JSON.parse(data);
  const colours = getColors(display.gradient);
  const highlight = getHighlight(display.tp_highlight);
  return (
    <div className="Table ReportTable">
      <table
        style={{
          textAlign: `${display.master.align}`,
          backgroundColor: `#${display.master.background_color}`,
          color: `#${display.master.font ? display.master.font.color : "000"}`,
          fontFamily: `${
            display.master.font ? display.master.font.name : "inherit"
          }`,
          fontSize: `${
            display.master.font ? Number(display.master.font.size) / 14 : 1
          }em`,
          position: "absolute",
          top: `${layout.top}%`,
          left: `${layout.left}%`,
          height: `${layout.height}%`,
          width: `${layout.width}%`,
          maxWidth: `${layout.width}%`,
          tableLayout: "fixed",
          zIndex: 10
        }}
      >
        <thead
          style={{
            fontWeight: `${
              display.columns.font && display.columns.font.bold
                ? "bold"
                : "normal"
            }`,
            color: `#${
              display.columns.font ? display.columns.font.color : "000"
            }`,
            backgroundColor: `#${display.columns.background_color}`
          }}
        >
          <tr>
            <th aria-label="empty cell" />
            {tableData.columns.map((i, key) => (
              <th
                style={{
                  fontWeight: highlight.find(o => o.x === 0 && o.y === key + 1)
                    ? "bold"
                    : "normal",
                  verticalAlign: "middle"
                }}
                key={_.uniqueId()}
              >
                {i}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.data.map((i, x) => (
            <tr key={_.uniqueId()}>
              <th
                style={{
                  backgroundColor: `#${
                    display.index ? display.index.background_color : "fff"
                  }`,
                  fontWeight: highlight.find(o => o.x === 0 && o.y === x + 1)
                    ? "bold"
                    : "normal",
                  borderTop: "1px solid #fff",
                  verticalAlign: "middle"
                }}
              >
                {tableData.index[x]}
              </th>
              {i.map((j, y) => (
                <td
                  style={{
                    color: `#${
                      colours.find(o => o.x === x + 1 && o.y === y + 1)?.colour
                    }`,
                    fontWeight:
                      highlight.find(o => o.x === x + 1 && o.y === 0) && "bold",
                    fontSize: 14,
                    borderTop: "1px solid #E1CDCC",
                    verticalAlign: "middle"
                  }}
                  key={_.uniqueId()}
                >
                  {j}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

ReportTable.propTypes = {
  data: PropTypes.string,
  display: PropTypes.shape(),
  layout: PropTypes.shape()
};

ReportTable.defaultProps = {
  data: "",
  display: {},
  layout: {}
};

export default ReportTable;
