import React from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { Box } from "@mui/material";
import { Paper } from "components/BaseComponents";
import Feedback from "components/Feedback";
import ReportTemplate from "./ReportTemplate";
import Layout from "./Layout";
import {
  ReportChart,
  ReportImage,
  ReportShape,
  ReportTable,
  ReportText
} from "./OldReport";

const styles = {
  presentation: { pb: "56.25%", overflow: "auto", position: "relative" }, // 9:16 ratio
  large: { display: { xs: "none", md: "block" } },
  small: { display: { md: "none", sm: "block" } }
};

const extractPrototypeContent = report => {
  const obj = report.find(item => item.formatkey === "prototype_content");
  if (obj && obj.content && Array.isArray(obj.content.prototype)) {
    return obj.content.prototype;
  }
  return [];
};

const Presentation = props => {
  const { activeSlideIndex, report, client, story, contents, navigateToSlide } =
    props;
  const slideData = report[activeSlideIndex];
  const prototypeArray = extractPrototypeContent(report);
  if (!slideData) {
    return (
      <div className="Presentation">
        <Paper variant="elevated" styles={styles.presentation}>
          <Box textAlign="center">No slide data.</Box>
        </Paper>
      </div>
    );
  }
  if (story !== "prr") {
    return (
      <div className="Presentation">
        <Paper
          variant="elevated"
          styles={{ ...styles.presentation, ...styles.small }}
        >
          <Box sx={{ p: 2, textAlign: "center" }}>
            Please view on a device with a larger screen.
          </Box>
        </Paper>
        <Paper
          variant="elevated"
          styles={{ ...styles.presentation, ...styles.large }}
        >
          <ReportTemplate
            activeSlideIndex={activeSlideIndex}
            client={client}
            story={story}
            formatKey={slideData.formatkey}
          />
          <Layout
            client={client}
            formatKey={slideData.formatkey}
            slideData={slideData}
            contents={contents}
            story={story}
            prototypeArray={prototypeArray}
            navigateToSlide={navigateToSlide}
          />
        </Paper>
        <Feedback component="report" />
      </div>
    );
  }
  return (
    <div className="Presentation">
      <Paper variant="elevated" styles={styles.presentation} id="presentation">
        <ReportTemplate
          activeSlideIndex={activeSlideIndex}
          client={client}
          story={story}
          slideKey={slideData.slidekey}
        />
        {slideData.images
          ? slideData.images.map(i => (
              <ReportImage key={uniqueId()} data={i.data} layout={i.layout} />
            ))
          : null}
        {slideData.text
          ? slideData.text.map(i => (
              <ReportText
                key={uniqueId()}
                data={i.data}
                display={i.display}
                layout={i.layout}
              />
            ))
          : null}
        {slideData.shapes
          ? slideData.shapes.map(i => (
              <ReportShape
                key={uniqueId()}
                data={i.data}
                display={i.display}
                layout={i.layout}
              />
            ))
          : null}
        {slideData.tables
          ? slideData.tables.map(i => (
              <ReportTable
                key={uniqueId()}
                data={i.data}
                display={i.display}
                layout={i.layout}
              />
            ))
          : null}
        {slideData.charts
          ? slideData.charts
              .filter(j => !(j.display.apply && j.display.apply[0] === "multi"))
              .map(i => (
                <ReportChart
                  key={uniqueId()}
                  data={i.data}
                  display={i.display}
                  layout={i.layout}
                  client={client}
                />
              ))
          : null}
        {/* Refactoring the data from the backend for the multi charts */}
        {/* currently assumes only 2 items: 0 is clustered_column or stacked_column, 1 is line OR 0 is line and 1 is clustered_column */}
        {slideData.charts &&
        slideData.charts.filter(
          j => j.display.apply && j.display.apply[0] === "multi"
        ).length > 0
          ? [
              {
                ...slideData.charts.filter(
                  j => j.display.apply && j.display.apply[0] === "multi"
                )
              }
            ].map(i => (
              <ReportChart
                key={uniqueId()}
                data={{
                  type: "multi",
                  0: i[0].data,
                  1: i[1].data
                }}
                display={{ 0: i[0].display, 1: i[1].display }}
                layout={i[1].layout}
                client={client}
              />
            ))
          : null}
      </Paper>
    </div>
  );
};

Presentation.propTypes = {
  activeSlideIndex: PropTypes.number,
  report: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string,
  story: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape()),
  navigateToSlide: PropTypes.func
};

Presentation.defaultProps = {
  activeSlideIndex: 0,
  report: [],
  client: "",
  story: "",
  contents: [],
  navigateToSlide: () => {}
};

export default Presentation;
