import _ from "lodash";
import { alertLevels } from "constants/enums";
import {
  GOTO_SLIDE,
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR
} from "../constants/actionConstants";
import { addNotification } from "./notification";
import logger from "../logger";
import { generatePPT as generatePptOld } from "../utils/generatePPT";
import { generatePPT } from "./generatePPT";

// hidden slide logic
function findSlide(slideIndex, report, direction, slideCount) {
  const isHidden = report[slideIndex].hidden;
  let slide = slideIndex;
  if (isHidden) {
    if (_.isEqual(direction, "prev")) {
      if (slideIndex - 1 > 0) {
        slide = findSlide(slideIndex - 1, report, "prev");
      }
    } else if (slideIndex + 1 < slideCount) {
      slide = findSlide(slideIndex + 1, report, "next", slideCount);
    } else {
      // if last slide is hidden, go back
      slide = findSlide(slideIndex, report, "prev");
    }
  }
  return slide;
}

export const goToSlide = (
  slideIndex,
  report,
  direction,
  slideCount,
  reportId,
  userId,
  story,
  client
) => {
  // to go to first slide
  if (slideIndex === 0) {
    return {
      type: GOTO_SLIDE,
      slideIndex
    };
  }
  const index = findSlide(slideIndex, report, direction, slideCount);
  const slideKey = report[index].slidekey;
  logger({
    date: new Date().toISOString(),
    action: "NAVIGATE_REPORT",
    report_id: reportId,
    slide_index: index + 1,
    slide_key: slideKey,
    total_slide_count: slideCount,
    user_id: userId,
    story,
    client
  });
  return {
    type: GOTO_SLIDE,
    slideIndex: index
  };
};

function downloadReportRequest(id) {
  return {
    type: DOWNLOAD_REPORT_REQUEST,
    id
  };
}

function downloadReportSuccess() {
  return {
    type: DOWNLOAD_REPORT_SUCCESS
  };
}

function downloadReportError(error) {
  return {
    type: DOWNLOAD_REPORT_ERROR,
    error
  };
}

export const generatePowerPoint =
  (
    report,
    reportId,
    story,
    title,
    userId,
    client,
    slideIndex,
    slideKey,
    navbar
  ) =>
  dispatch => {
    dispatch(downloadReportRequest(reportId));
    try {
      const ppt =
        story.toLowerCase() !== "prr"
          ? generatePPT(report, client, story, navbar)
          : generatePptOld(report, client);
      ppt
        .writeFile({ fileName: `${title.replaceAll("/", "-")}.pptx` })
        .then(() => {
          logger({
            date: new Date().toISOString(),
            action: "DOWNLOAD_REPORT",
            report_id: reportId,
            user_id: userId,
            download_url: reportId,
            story,
            slide_index: slideIndex + 1,
            slide_key: slideKey,
            client
          });
          dispatch(downloadReportSuccess());
        })
        .catch(err => {
          dispatch(
            addNotification(
              "There was an error downloading the file",
              alertLevels.ERROR
            )
          );
          dispatch(downloadReportError(err.message));
        });
    } catch (err) {
      dispatch(downloadReportError(err.message));
      dispatch(
        addNotification(
          "There was an error generating the download file",
          alertLevels.ERROR
        )
      );
    }
  };
