import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { formatNumber } from "utils";
import { clientConfig, CHART_COLORS } from "config";
import ErrorBoundary from "components/ErrorBoundary";
import {
  ClusteredColumn,
  ClusteredBar,
  StackedBar,
  StackedColumn,
  Multi,
  PieGraph,
  BubbleChart,
  LineChart
} from "./Charts";

const FallbackComponent = (
  <Box sx={{ textAlign: "center", color: "negative.main", py: 1 }}>
    <Typography variant="body1" gutterBottom>
      Oops, something went wrong :(
    </Typography>
    <Typography variant="body1">Error displaying chart</Typography>
  </Box>
);

const ReportChart = props => {
  const { data, display, layout, client } = props;
  const { chartColors = CHART_COLORS } = clientConfig[client] || {};
  return (
    <div className="Chart ReportChart">
      <div
        className={data.type}
        style={{
          position: "absolute",
          top: `${layout.top}%`,
          left: `${layout.left}%`,
          height: `${layout.height}%`,
          width: `${layout.width}%`
        }}
      >
        {data.type === "clustered_column" ? (
          <ErrorBoundary fallback={FallbackComponent}>
            <ClusteredColumn
              data={data}
              display={display}
              colors={chartColors}
              formatNumber={formatNumber}
            />
          </ErrorBoundary>
        ) : null}
        {data.type === "clustered_bar" ? (
          <ErrorBoundary fallback={FallbackComponent}>
            <ClusteredBar
              data={data}
              display={display}
              formatNumber={formatNumber}
            />
          </ErrorBoundary>
        ) : null}
        {data.type === "stacked_bar" ? (
          <ErrorBoundary fallback={FallbackComponent}>
            <StackedBar
              data={data}
              display={display}
              colors={chartColors}
              formatNumber={formatNumber}
            />
          </ErrorBoundary>
        ) : null}
        {data.type === "stacked_column" ? (
          <ErrorBoundary fallback={FallbackComponent}>
            <StackedColumn
              data={data}
              display={display}
              colors={chartColors}
              formatNumber={formatNumber}
            />
          </ErrorBoundary>
        ) : null}
        {data.type === "multi" ? (
          <ErrorBoundary fallback={FallbackComponent}>
            <Multi data={data} display={display} colors={chartColors} />
          </ErrorBoundary>
        ) : null}
        {data.type === "pie" ? (
          <ErrorBoundary fallback={FallbackComponent}>
            <PieGraph display={display} data={data} colors={chartColors} />
          </ErrorBoundary>
        ) : null}
        {data.type === "bubble" ? (
          <ErrorBoundary fallback={FallbackComponent}>
            <BubbleChart display={display} data={data} />
          </ErrorBoundary>
        ) : null}
        {data.type === "line" ? (
          <ErrorBoundary fallback={FallbackComponent}>
            <LineChart display={display} data={data} />
          </ErrorBoundary>
        ) : null}
      </div>
    </div>
  );
};

ReportChart.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  layout: PropTypes.shape(),
  client: PropTypes.string
};

ReportChart.defaultProps = {
  data: {},
  display: {},
  layout: {},
  client: ""
};

export default ReportChart;
