import React from "react";
import PropTypes from "prop-types";
import PlotlyChart from "components/PlotlyChart";

const PieGraph = props => {
  const { display, data, colors } = props;
  return (
    <PlotlyChart
      data={[
        {
          values: data.y,
          labels: data.x,
          type: "pie",
          texttemplate: data.x.map(
            (i, k) => display.data_labels.individual_map[0][k]
          ),
          insidetextorientation: "horizontal",
          textposition: "inside",
          textfont: { color: "#FFFFFF", size: 12 },
          cliponaxis: false,
          marker: { colors: data.x.map((i, k) => colors[k % colors.length]) }
        }
      ]}
      layout={{
        margin: { t: 20, b: 20, r: 20, l: 20 },
        showlegend: false
      }}
    />
  );
};

PieGraph.propTypes = {
  display: PropTypes.shape(),
  data: PropTypes.shape(),
  colors: PropTypes.arrayOf(PropTypes.string)
};

PieGraph.defaultProps = {
  display: {},
  data: {},
  colors: []
};

export default PieGraph;
